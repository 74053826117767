.container {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto 20px;
}

.container input {
  background: var(--main);
  color: var(--background);
}

.toggle-visibility-btn {
  border: none;
  outline: none;
  background: none;

  position: absolute;

  top: calc(50% - 12px);
  right: 6px;

  cursor: pointer;
}

.toggle-visibility-btn img {
  width: 24px;
}

@media (min-width: 768px) {
  .toggle-visibility-btn {
    top: calc(50% - 15px);
    right: 10px;
  }
  .toggle-visibility-btn img {
    width: 30px;
  }
}
