.main {
  width: 1000px;
  max-width: calc(100% - 30px);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.main input {
  font-size: 16px;
  width: 100%;

  padding: 16px 12px;

  border-radius: 6px;
  border: none;

  outline: none;
}

.errors {
  overflow-y: scroll;

  padding-bottom: 50px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.errors::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.errors {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.validation-item {
  margin-bottom: 12px;
  font-size: 14px;
  position: relative;
  line-height: 1.2;
}

.validation-indicator {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.validation-message {
  display: block;
  margin-left: 22px;
}

@media (min-width: 576px) {
  .main input {
    font-size: 24px;
  }

  .validation-item {
    font-size: 20px;
    line-height: 1.25;
  }

  .validation-message {
    margin-left: 28px;
  }
}

/* RESPONSIVE: */
@media (min-width: 768px) {
  .main input {
    font-size: 32px;
  }

  .validation-item {
    line-height: 1.3;
    font-size: 24px;
  }

  .validation-message {
    margin-left: 36px;
  }
}

/* ANIMATIONS: */
.validation-item-enter {
  opacity: 0;
}
.validation-item-enter-active {
  opacity: 1;
  transition: opacity var(--validation-item-animation-timeout, 250ms) ease-out;
}
.validation-item-exit {
  opacity: 1;
}
.validation-item-exit-active {
  opacity: 0;
  transition: opacity var(--validation-item-animation-timeout, 250ms) ease-out;
}

.validation-indicator-enter {
  transform: rotateY(90deg);
}
.validation-indicator-enter-active {
  transform: rotateY(0);
  transition: transform var(--validation-indicator-animation-timeout, 250ms);
  transition-delay: var(--validation-indicator-animation-timeout, 250ms);
}
.validation-indicator-exit {
  transform: rotateY(0);
}
.validation-indicator-exit-active {
  transform: rotateY(-90deg);
  transition: transform var(--validation-indicator-animation-timeout, 250ms);
}


